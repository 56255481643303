import $ from 'jquery'
import moment from 'moment'
import 'daterangepicker'

class Range {
  constructor(selector, storage) {
    this.selector = selector;
    this.storage = storage;

    this.init();
  }

  init() {
    $(this.selector).daterangepicker({
      startDate: this.storage.params.start_date,
      endDate: this.storage.params.end_date,
      ranges: this.defaultRanges,
      showToday: false,
      maxSpan: {
        days: 720
      }
    }).
    on('apply.daterangepicker', (e, picker) => {
      const start_date = this.storage.byTimezone(picker.startDate);
      const end_date = this.storage.byTimezone(picker.endDate);

      this.storage.updateRange({ start_date, end_date });
    });

    this.refreshLabel();
  }

  update() {
    this.instance.setStartDate(this.storage.params.start_date);
    this.instance.setEndDate(this.storage.params.end_date);

    this.refreshLabel();
  }

  refreshLabel() {
    this.instance.calculateChosenLabel();

    this.setLabel(this.instance.startDate, this.instance.endDate, this.instance.chosenLabel);
  }

  setLabel(start_date, end_date, label = null) {
    if (label === null || label === 'Custom Range') {
      const [startDate, endDate] = [start_date.format('MMM-DD-Y'), end_date.format('MMM-DD-Y')];

      label = (startDate == endDate) ? startDate : [startDate, endDate].join(' - ');
    }

    const button = `
      <button class="btn btn-outline-primary btn-range dropdown-toggle">
        <div>${label}</div>
        <div class="mdi mdi-chevron-down"></div>
      </button>`;

    $(this.selector).html(button);
  }

  get defaultRanges() {
    return {
      'Last 30 Days': [
        this.currentDate.subtract(29, 'days'),
        this.currentDate
      ],
      'This Month': [
        this.currentDate.startOf('month'),
        this.currentDate.endOf('month')
      ],
      'Last Month': [
        this.currentDate.subtract(1, 'month').startOf('month'),
        this.currentDate.subtract(1, 'month').endOf('month')
      ],
      'This Quarter': [
        this.currentDate.startOf('quarter'),
        this.currentDate.endOf('quarter')
      ],
      'Last Quarter': [
        this.currentDate.subtract(1, 'quarter').startOf('quarter'),
        this.currentDate.subtract(1, 'quarter').endOf('quarter')
      ],
      'Last 365 Days': [
        this.currentDate.subtract(364, 'days'),
        this.currentDate
      ],
      'This Year': [
        this.currentDate.startOf('year'),
        this.currentDate.endOf('year')
      ],
      'Last Year': [
        this.currentDate.subtract(1, 'year').startOf('year'),
        this.currentDate.subtract(1, 'year').endOf('year')
      ]
    };
  }

  get currentDate() {
    return moment().clone().utcOffset(window.metrics.tz_offset).hour(0).minute(0).second(0);
  }

  get instance() {
    return $(this.selector).data('daterangepicker');
  }
}

export default Range
