import { Controller } from 'stimulus'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import configureStore from 'src/react/store/configureStore'
import trainings from 'src/react/store/TrainingWizard'

import Page from 'src/react/containers/TrainingWizard/Page'

export default class extends Controller {
  connect() {
    const root = ReactDOM.createRoot(this.element);

    root.render(
      <Provider store={this.store}>
        <Page />
      </Provider>
    );
  }

  get store() {
    return configureStore({ trainings });
  }
}
