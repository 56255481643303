import { Controller } from 'stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  initialize() {
    this.loaded = false;
  }

  connect() {
    this.initializeWidget();
  }

  initializeWidget() {
    const self = this;

    this.instance = tippy(this.element, {
      theme: 'light-border',
      placement: 'right',
      maxWidth: 'none',
      content: 'Loading...',
      allowHTML: true,
      interactive: true,
      appendTo: document.body,
      onShow(instance) {
        self.onShow(instance);
      }
    });
  }

  onShow(instance) {
    if (this.loaded) return;

    this.loaded = true;

    const request = $.ajax({
      url: this.urlValue,
      type: 'GET',
      dataType: 'json'
    });

    request.
      done((response) => this.resolve(response)).
      fail(jqXHR => this.reject(jqXHR));
  }

  reject(jqXHR) {
    this.instance.setContent(`Request failed. ${jqXHR.statusText}`);
  }

  static get surveyLibrary() {
    return {
      vision_worse: 'Has your vision become worse in last 4 weeks?',
      vision_worse_from_prev_test: 'Has your vision become worse from the last test?',
      wear_glasses: 'Do you wear glasses for reading?',
      wear_glasses_now: 'Are you wearing your reading glasses now?',
      is_glasses_strong: 'Do you think your reading glasses are strong enough?',
      need_but_not_have_glasses: 'Do you know you need reading glasses but just don\'t have them?',
      maybe_hyperopia: 'Do you think it is easier to read when you hold the reading material further away from you?',
      which_eye_worse: 'Which eye has the worsening of vision?'
    }
  }

  renderAnswer(survey, key) {
    switch(key) {
      case 'which_eye_worse':
        return this.renderWhichEyeWorse(survey[key]);
      default:
        return this.renderDefaultAnswer(survey[key]);
    }
  }

  renderWhichEyeWorse(answer) {
    const eye = (answer) => {
      if (answer.length == 0) return 'N/A';
      if (answer.length == 2) return 'Both';

      return {'l': 'Left', 'r': 'Right'}[answer[0]];
    }

    return `<span class="badge badge-secondary">${eye(answer)}</span>`
  }

  renderDefaultAnswer(answer) {
    return answer ?
      '<span class="badge badge-primary">Yes</span>' :
      '<span class="badge badge-secondary">No</span>';
  }

  withResults(results) {
    return results && results.status == 'ready';
  }

  buildContainer(template) {
    const container = document.createElement('div');
    container.innerHTML = template;

    return container;
  }
}
