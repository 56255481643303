import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    if (data.location) {
      window.location = data.location;
    }
  }

  onError(event) {
    let [data, _status, _xhr] = event.detail;

    $('#edit-modal .errors-context').
      findOrAppend('.message', '<div class="message" />').
      html(data.errors);
  }
}
