import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'src/react/store/Compares/selectors'

const Color = ({ tests }) => {
  const { 'color:l': left, 'color:r': right } = tests;

  const showAll = useSelector(state => selectors.showAll(state));
  const leftVisible = useSelector(state => selectors.leftVisible(state));
  const rightVisible = useSelector(state => selectors.rightVisible(state));

  const platesRecognized = (results) => {
    return `${results.recognized_plates} of ${results.shown_plates} plates recognized`;
  }

  if (!showAll) return null;

  return (
    <div className="result-item">
      <div className="title">
        <span>Color</span>
      </div>
      <div className="content">
        <div className="row">
          {
            leftVisible && (
              <div className="col">
                <div className="d-flex">
                  <span>
                    {left ? platesRecognized(left) : 'N/A'}
                  </span>
                </div>
              </div>
            )
          }
          {
            rightVisible && (
              <div className="col">
                <div className="d-flex">
                  <span>
                    {right ? platesRecognized(right) : 'N/A'}
                  </span>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default Color;
