import { Controller } from 'stimulus'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import configureStore from 'src/react/store/configureStore'
import compares from 'src/react/store/Compares'

import Page from 'src/react/containers/Compares/Page'

export default class extends Controller {
  connect() {
    const root = ReactDOM.createRoot(this.element);

    root.render(
      <Provider store={this.store}>
        <Page />
      </Provider>
    );
  }

  get store() {
    return configureStore({ compares }, this.initialState);
  }

  get initialState() {
    return {
      compares: {
        isLoading: true,
        isComplete: false,
        cycles: [],
        blacklist: [],
        hasOverflow: false,
        canScrollLeft: false,
        canScrollRight: false,
        leftVisible: true,
        rightVisible: true,
        showAll: true,
        page: null,
        totalCount: 0,
        currentCount: 0,
        loadedCount: 0,
        errors: null,
        flash: null,
        transmitBusy: false
      }
    };
  }
}
