import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'src/react/store/Compares/selectors'
import classnames from 'classnames'

import { Grid, NoGrid } from './Grid'

const Amsler = ({ tests }) => {
  const { 'amsler:l': left, 'amsler:r': right } = tests;

  const leftVisible = useSelector(state => selectors.leftVisible(state));
  const rightVisible = useSelector(state => selectors.rightVisible(state));

  const leftColumnVisibility = classnames('col-6', 'p-0', { 'd-none': !leftVisible });
  const rightColumnVisibility = classnames('col-6', 'p-0', { 'd-none': !rightVisible });

  return (
    <div className="result-item">
      <div className="title">
        <span>Amsler-VF</span>
      </div>
      <div className="content">
        <div className="amsler-zone">
          <span>Central 5-Degrees</span>
        </div>
        <div className="row justify-content-center">
          <div className={leftColumnVisibility}>
            {
              left ?
                <Grid
                  type="central"
                  payload={
                    left['central']
                  }
                /> :
                <NoGrid />
            }
          </div>
          <div className={rightColumnVisibility}>
            {
              right ?
                <Grid
                  type="central"
                  payload={
                    right['central']
                  }
                /> :
                <NoGrid />
            }
          </div>
        </div>
        <div className="amsler-zone">
          <span>QUAD Central 10-Degrees</span>
        </div>
        <div className="row justify-content-center">
          <div className={leftColumnVisibility}>
            {
              left ?
                <Grid
                  type="full"
                  payload={
                    left['full']
                  }
                /> :
                <NoGrid />
            }
          </div>
          <div className={rightColumnVisibility}>
            {
              right ?
                <Grid
                  type="full"
                  payload={
                    right['full']
                  }
                /> :
                <NoGrid />
            }
          </div>
        </div>
        <div className="amsler-zone">
          <span>Central 5-Degrees &amp; QUAD Central 5-Degrees</span>
        </div>
        <div className="amsler-zone-chapter">
          Central 5-Degrees
        </div>
        <div className="row justify-content-center">
          <div className={leftColumnVisibility}>
            {
              left ?
                <Grid
                  type="compare"
                  payload={
                    left['central']
                  }
                /> :
                <NoGrid />
            }
          </div>
          <div className={rightColumnVisibility}>
            {
              right ?
                <Grid
                  type="compare"
                  payload={
                    right['central']
                  }
                /> :
                <NoGrid />
            }
          </div>
        </div>
        <div className="amsler-zone-chapter">
          QUAD Central 5-Degrees
        </div>
        <div className="row justify-content-center">
          <div className={leftColumnVisibility}>
            {
              left ?
                <Grid
                  type="compare"
                  payload={
                    left['full_central']
                  }
                /> :
                <NoGrid />
            }
          </div>
          <div className={rightColumnVisibility}>
            {
              right ?
                <Grid
                  type="compare"
                  payload={
                    right['full_central']
                  }
                /> :
                <NoGrid />
            }
         </div>
        </div>
      </div>
    </div>
  );
}

export default Amsler
