import { Controller } from 'stimulus'
import moment from 'moment'

export default class extends Controller {
  static targets = ['periods', 'from', 'to']

  connect() {
    this.configureMoment();
    this.attachListeners();
  }

  disconnect() {
    this.removeListeners();
  }

  attachListeners() {
    this.periodsTarget.addEventListener('change', () => this.changePeriod());
    this.fromTarget.addEventListener('click', () => this.resetPeriod());
    this.toTarget.addEventListener('click', () => this.resetPeriod());
  }

  removeListeners() {
    this.periodsTarget.removeEventListener('change', () => this.changePeriod());
    this.fromTarget.removeEventListener('click', () => this.resetPeriod());
    this.toTarget.removeEventListener('click', () => this.resetPeriod());
  }

  rangeByType(type) {
    switch (type) {
      case 'this_week':
        return [
          moment().startOf('week'), moment()
        ];
      case 'last_week':
        return [
          moment().subtract(1, 'week').startOf('week'),
          moment().subtract(1, 'week').endOf('week')
        ];
      case 'this_month':
        return [
          moment().startOf('month'), moment()
        ];
      case 'last_month':
        return [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')
        ];
      case 'last_30_days':
        return [
          moment().subtract(29, 'days'),
          moment()
        ];
      default:
        throw new Error('Period is not defined');
    }
  }

  changePeriod() {
    if (this.periodsTarget.value == '') {
      return this.clearDatepickerRange();
    }

    const [from, to] = this.rangeByType(this.periodsTarget.value);

    this.setDatepickerRange(
      from.hour(0).minute(0).second(0),
      to.hour(23).minute(59).second(59)
    );
  }

  clearDatepickerRange() {
    $(this.fromTarget).data('datepicker').clear();
    $(this.toTarget).data('datepicker').clear();
  }

  setDatepickerRange(from, to) {
    $(this.fromTarget).data('datepicker').selectDate(from.toDate());
    $(this.toTarget).data('datepicker').selectDate(to.toDate());
  }

  resetPeriod() {
    this.periodsTarget.selectedIndex = 0;
  }

  configureMoment() {
    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week (0 = Sunday, 1 = Monday)
      },
    });
  }
}